import axios from "axios";
// import { getAllSessions, getAllUnitsSessionDetails, getBarCode, getProductDetails } from "../Mock/responses";
// import { BarchartSessions, DayWiseSession, drop_down_config, perforationChartMock, PieChartData, units, weightChart } from "../Mock/dashboard";

export const ROUTES = {
  LOGIN: "/auth/admin/login",

  VERIFY_OTP: "/auth/admin/otp/verify",
  
  // GET_ALL_PENDING_DRIVERS :'/admin/driver/registration/submission',

  GET_SINGLE_DRIVER_DETAILS:'/admin/driver/registration/{contact_id}',

  GET_ALL_NEW_REGISTRATIONS : '/admin/driver/registration/submission',

  APPROVE_APPLICATION :'/admin/driver/registration/{contact_id}/approve',

  REJECT_APPLICATION :'/admin/driver/registration/{contact_id}/reject',

  GET_ALL_DRIVERS : '/admin/driver/all',

  SEND_MESSAGE_APPLICATION : '/admin/driver/registration/{contact_id}/message',

  GET_ALL_TRANSPORTS: '/driver/alltransports',

  DASH_API: '/dash/gen-insights'

};

class ApiService {
  constructor() {
    if (new.target === ApiService) {
      throw new Error("Cannot instantiate abstract class");
    }
  }

  async isLoggedIn() {}

  async login() {}

  async get() {}

  async post() {}

  async patch() {}
}

class Production extends ApiService {
  #host;
  constructor() {
    super();
    // this.#host = process.env.REACT_APP_API_URL_PREFIX.length === 0 ? "http://localhost:8000/api/v1":process.env.REACT_APP_API_URL_PREFIX;
    this.#host = "https://api.dev.zanzoom.com/api/v1"
    // console.log('the host is ',process.env.REACT_APP_API_URL_PREFIX.length)
  }

  async isLoggedIn() {
    if (
      localStorage.getItem("token") === null &&
      sessionStorage.getItem("token") === null
    )
      return false;
    return true;
  }

  async login(user, pwd) {
    try {
      const res = await axios.post(
        `${this.#host}${ROUTES.LOGIN}`,
        { username: user, password: pwd },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
      console.log(res);
      localStorage.setItem("token", res.data.access_token);
      return res;
    } catch (e) {
      console.log(e);
      return { status: e.response.status, data: e.response.data.detail };
    }
  }

  async get(endpoint,payload) {
    try {
      const res = await axios.get(`${this.#host}${endpoint}`, {
        params: payload ?? {},
        headers: this.#getHeaders(),
      });
      return res;
    } catch (e) {
      console.log(e)
      console.log(e.response)
      return e.response
    }
  }

  async sendOTPLogin(endpoint, payload) {
    try {
      const res = await axios.post(`${this.#host}${endpoint}`, payload, {
        // params: payload ?? {},
        
      });
      return res;
    } catch (e) {
      console.error(e);
      return e.response;
    }
  }

  async verifyOTP(endpoint, payload) {
    try {
      const res = await axios.post(`${this.#host}${endpoint}`, payload, {
        // params: payload ?? {},
        
      });
      return res;
    } catch (e) {
      console.error(e);
      return e.response;
    }
  }

  

  async post(endpoint, payload) {
    try {
      const res = await axios.post(`${this.#host}${endpoint}`, payload, {
        // params: payload ?? {},
        headers: this.#getHeaders(),
      });
      return res;
    } catch (e) {
      console.error(e);
      return e.response;
    }
  }

  async patch(endpoint,payload) {
    try {
      const res = await axios.patch(
        `${this.#host}${endpoint}`,
          payload,
        {
          // params: payload ?? {},
          headers: this.#getHeaders(),
        }
      );
      return res;
    } catch (e) {
      return e;
      //    console.error(e);
    }
  }

  #getHeaders() {
    return {
      token: `Bearer ${
        localStorage.getItem("token") !== null
          ? localStorage.getItem("token")
          : sessionStorage.getItem("token")
      }`,
    };
  }
}

class Mock extends ApiService {
  #tokenType;
  #data;
  #counter; 

  constructor(){
    super();
    this.#counter = 0;
  }

  
  

//   #getHeaders() {
//     return {
//       Authorization: `Bearer ${
//         localStorage.getItem("token") !== null
//           ? localStorage.getItem("token")
//           : sessionStorage.getItem("token")
//       }`,
//     };
//   }
}

export const apiService = new Production() ;
